.roadmap {
  font-family: "Space Grotesk", sans-serif;

  .Accordion {
    max-height: calc(100vh - 126px);
    overflow: scroll;

    .accordionItem {
      background-color: #13131b;
      border-color: #13131b;
      border-radius: 15px;
      color: #a6a7a6;

      .Header {
        button {
          min-height: 117px;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          background-color: transparent;
          box-shadow: unset !important;
          color: #fff;
          padding: 25px;
          padding-right: 55px;
        }
      }

      .body {
        padding: 25px;
        padding-top: 0;
      }
    }
  }
}