.authentication {
  min-height: 100vh;

  .authFormWrpper {
    background: linear-gradient(186.17deg,
        #1c1950 -41.88%,
        #3c4086 -1.99%,
        #3b4e8d 43.94%,
        #3891ae 128.42%,
        #2d4569 151.85%,
        #43e2f7 204.58%);
    border: 1px solid rgba(49, 155, 255, 0.5) !important;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px !important;

    .telegramBtn {
      background-color: #2aabed;
      border-color: #2aabed;
      font-size: 14px;
      height: 45px;
    }
  }
}