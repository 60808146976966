.refferals {
  font-family: "Space Grotesk", sans-serif;
  .mSpinner {
    color: #02a9a8;
  }
  .top {
    h5 {
      font-size: 18px;
      color: #54B3F5;
    }
  }

  .tab {
    font-size: 18px;
    background-color: #000000;
    height: 46px;
    border: 1px solid #000000;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;

    &.active {
      background-color: #242AFC;
    }

    &:after {
      border-radius: 7px;
    }
  }

  .cardCstm {
    background: #0C0C0C;
    // border: 0.904206px solid #101017;
    // box-shadow: 0px 5.42523px 7.23365px rgba(0, 0, 0, 0.25);
    border-radius: 18.0841px;

    table {
      thead {
        th {
          font-size: 10px;
          color: #87878b;
          padding-bottom: 20px;
    padding-top: 10px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-align: left;
        }
      }

      tbody {
        tr:nth-child(odd) {
        
          background: #21212E;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        }
        tr:nth-child(even) {
          background: #13131B;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        }

        tr {
          // position: relative;
          // z-index: 99;

          td {
            font-size: 12px;
            color: #9da1c3;
            padding-left: 20px !important;
            padding-right: 20px !important;
            text-align: left;

            * {
              font-size: 12px;
              font-weight: 500 !important;
            }

            .dropdown {
              >button {
                position: relative;
                height: 31px;
                font-size: 12px;
                color: #fff;
                font-weight: 400 !important;


                background: #1D1D2A;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);


                border-radius: 10px !important;

                &:after {
                  display: none;
                }
              }

              .DropdownMenu {
                background: #1D1D2A;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
                margin-top: 10px;
                padding-bottom: 30px !important;

                .dropIcn {
                  path {
                    fill: #1D1D2A;
                    stroke: #1D1D2A;
                  }
                }

                ol {
                  li {
                    // border-bottom: 1px solid #5db8ff3b;
                  }
                }
              }
            }
          }

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
}