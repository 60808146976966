.gameHistory {
  .mSpinner {
    color: #02a9a8;
  }

  .cardCstm {
    background: #101017;
    border: 0.904206px solid #101017;
    box-shadow: 0px 5.42523px 7.23365px rgba(0, 0, 0, 0.25);
    border-radius: 18.0841px;

    table {
      thead {
        th {
          font-size: 12px;
          color: #87878b;
        }
      }

      tbody {
        tr:nth-child(odd) {
          &:after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
          }

          //   background: linear-gradient(
          //     142.68deg,
          //     #024677 -138.11%,
          //     #0a699d 30.49%,
          //     rgba(67, 226, 247, 0.51) 141.28%
          //   );
          //   // opacity: 0.7;
          //   box-shadow: 0px 5.40534px 7.20713px rgba(0, 0, 0, 0.15);
          //   border-radius: 7.20713px;
          /* Frame 1000004819 */

          background: #13131b;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        }

        tr {
          // position: relative;
          // z-index: 99;

          td {
            font-size: 12px;
            color: #9da1c3;

            * {
              font-size: 14px;
              font-weight: 600 !important;
            }

            .dropdown {
              >button {
                position: relative;
                height: 31px;
                font-size: 12px;
                color: #fff;
                font-weight: 400 !important;


                background: #1D1D2A;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);


                border-radius: 10px !important;

                &:after {
                  display: none;
                }
              }

              .DropdownMenu {
                background: #1D1D2A;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
                margin-top: 10px;
                padding-bottom: 30px !important;

                .dropIcn {
                  path {
                    fill: #1D1D2A;
                    stroke: #1D1D2A;
                  }
                }

                ol {
                  li {
                    // border-bottom: 1px solid #5db8ff3b;
                  }
                }
              }
            }
          }

          td:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          td:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }
}