.taskList {
    .mSpinner {
        color: #02a9a8;
    }

    .cardCstm {
        border-radius: 16px;
        font-size: 14px;
        line-height: 20px;
        transition: 0.4s;
        background: #13131B;

        // &:hover {
        //     background: linear-gradient(90deg, rgba(7, 160, 196, 0.7) 0%, rgba(16, 137, 188, 0.7) 51%, rgba(8, 92, 152, 0.7) 100%);
        // }

        &.opened {
            background: linear-gradient(90deg, rgba(7, 160, 196, 0.7) 0%, rgba(16, 137, 188, 0.7) 51%, rgba(8, 92, 152, 0.7) 100%);
        }


        &.active {
            background: #0590CC;
            border: 1px solid #5DF4AA;
        }
    }
}