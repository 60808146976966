.ViewPop {
    font-family: "Space Grotesk", sans-serif;
  
    .mSpinner {
      color: #02a9a8;
    }
  
    button {
      font-size: 18px !important;
    }
  }