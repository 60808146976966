.LinkAccountPop {
  font-family: "Space Grotesk", sans-serif;

  form {
    color: #c1dbe6;
  }

  button {
    font-size: 16px !important;

    &.submit {
      background: #02bcc0;
      box-shadow: 0px 2.635px 3.51333px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }
}