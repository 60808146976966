footer {
  .innerWrp {
    // background: linear-gradient(90deg, #13A7CD 0%, #0E6DAC 100%);
    // border: 0.7px solid #1ABDE2;
    // border-radius: 16px;
    background-color: #0c0c0c;

    .link {
      text-decoration: none;
      font-size: 9px;
      font-weight: 500;
      // opacity: 0.6;

      span {
        padding-bottom: 3px;

        svg {
          height: 25px;
          width: 25px;
        }
      }

      &.active {
        opacity: 1;
        color: #78edff !important;
      }
    }
  }
}

@media (min-height: 900px) {
  footer {
    position: relative !important;
  }
}

@media (max-height: 900px) {
  footer {
    position: fixed !important;
  }
}