.buyPop {
  font-family: "Space Grotesk", sans-serif;

  .box {
    z-index: 9;

    background: #13131b;
    border-radius: 18.8559px;
  }

  .quantity {
    .formControl {
      width: 55px;
      height: 38px;
      background: #0c0c0c;
      border: 0.2px solid #0c0c0c;
      border-radius: 8px;
      box-shadow: unset !important;
      user-select: none;
    }

    button {
      width: 38px;
      height: 28px;

      background: linear-gradient(198.21deg, #00defa -11.18%, #6007f7 144.37%);
      box-shadow: 0px 5.02824px 5.02824px rgba(0, 0, 0, 0.15);
      border-radius: 10.0565px;
      opacity: .5;

      &.active {
        background: linear-gradient(195.85deg, #0ac7fa -0.69%, #3d55f8 88.94%);
        opacity: 1;
      }
    }
  }

  .submitBtn {
    font-size: 20px;
  }
}