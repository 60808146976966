.gameHistoryDetail {
  * {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  .mSpinner {
    color: #02a9a8;
  }

  .countList {
    z-index: 9;

    .level {
      color: #858586;

      &.active {
        color: #fff;

        svg * {
          fill: #fff;
        }
      }
    }

    // &:after {
    //   position: absolute;
    //   content: "";
    //   height: 100%;
    //   right: 1px;
    //   top: 0;
    //   width: 9px;
    //   z-index: -1;
    //   background-color: #003147;
    //   border-radius: 10px;
    // }
  }

  .gameOptionWrp {
    width: 100%;
    display: grid;
    gap: 2px;
    padding-left: 25px !important;

    .listCount {
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      font-size: 12px;
      color: #858586;
      left: -30px;
    }

    li:nth-child(odd) {
      margin-bottom: -5px;
      margin-top: -7px;
      padding-right: 25px;
    }

    li:nth-child(even) {
      margin-top: -4px;
      padding-left: 25px;
    }

    li {
      gap: 5px;

      .option {
        width: 20%;
        flex-shrink: 0;
        // opacity: 0.7;
        aspect-ratio: 1;
        z-index: 9;

        // height: 55px;
        // background: #13131b;
        // border: 2px solid #13131b;
        // border-radius: 10px;
        // clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
        // box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.65);
        .hexIcn {
          top: 50%;
          left: 0;
          width: 100%;
          z-index: -1;
          transform: translateY(-50%);

          svg {
            aspect-ratio: 1;
            width: 100%;
            height: 100%;

            * {
              width: 100%;
              height: 100%;
            }
          }
        }

        // &.active {
        //   background-color: #64c2d1;
        //   opacity: 1;
        //   cursor: pointer;
        // }

        &.rightAns {
          // opacity: 1;
          // animation: flipX 2s;

          // background: #0e1d2d;
          // border: 1.88096px solid #242afc;
          // box-shadow: inset 0px 3.76192px 11.2858px rgba(0, 0, 0, 0.25);
        }

        &.wrongAns {
          // opacity: 1;
          // animation: flipX 2s;
          // background: #0e1d2d;
          // border: 1.88096px solid #ed4032;
          // box-shadow: inset 0px 3.76192px 11.2858px rgba(0, 0, 0, 0.25);
        }

        .value {
          font-family: Space Grotesk, sans-serif;
          font-size: 7px;
          font-weight: 700;
          line-height: 11px;
          margin-bottom: -10px;
        }
      }
    }
  }

  .topWrpper {

    background: #13131B;
    // border: 0.744927px solid #343452;
    border-radius: 11.1739px;
  }

  .historyList {

    background: #0C0C0C;
    border: 0.744927px solid #343452;
    border-radius: 11.1739px;




    ol {
      .numb {
        font-size: 14px;
      }

      .optionHist {
        width: 25%;
        aspect-ratio: 1;
        opacity: 0.7;
        background: #004E8C;
        border: 1px solid #4564a2;
        border-radius: 5px;

        &.active {
          opacity: 1;
          border-color: #1debb2;
        }

        &.bomb {
          background: #4f4366;
          opacity: 1;
          border: 0.7px solid #ae3a4d;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .gameHistoryDetail {
    display: flex !important;
    align-items: end;

    >div {
      width: 100%;

      >div {
        max-height: 90% !important;

      }
    }
  }
}