// .referralSec {
//     .mSpinner {
//         color: #02a9a8;
//     }

//     .cardCstm {
//         overflow: hidden;
//         z-index: 9;
//         color: #fff;
//         font-size: 14px;
//         line-height: 20px;
//         font-family: "Space Grotesk", sans-serif;
//         background: #13131B;
//         border-top-left-radius: 16px;
//         border-top-right-radius: 16px;

//         h6 {
//             font-family: "Nunito";
//         }

//         * {
//             z-index: 99;
//             font-family: "Space Grotesk";
//         }

//         .btn {
//             min-width: 74.48px;
//             height: 29.34px;
//             font-size: 12px;
//             font-weight: 700;
//             border-color: #02bcc0;
//             background: #02bcc0;
//             box-shadow: 0px 2.635px 3.51333px rgba(0, 0, 0, 0.25);
//             border-radius: 5.72957px;
//         }
//     }
// }

.FriendsSec {
    font-family: "Space Grotesk", sans-serif;
    background: #0C0C0C;
    color: #909095;

  

        .bottomSec {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            height: calc(100vh - 129px);
            overflow: scroll;
    
        .cardCstm {
            background: #21212E;
            border-radius: 15px;
            line-height: 20px;
            font-size: 17px;
            font-weight: 500;

            h6 {
                font-size: 20px;
            }

            button {
                color: #909095;
                font-weight: 500;
                line-height: 20px;
                font-size: 17px;
            }

            hr {
                border-color: #909095;
                border-width: 0.33px;
            }
        }

        .btnWrpper {
            button {
                background: #02BCC0;
                border-color: #02BCC0;
                box-shadow: 0px 2.635px 3.51333px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
            }
        }
    }
}