main {
    position: relative;
    padding-bottom: 70px;

    .center {
        background-color: #0c0c0c;
    }

    >div {
        gap: 30px;

    }

    .cardCstm {
        background: #191922;
        border-radius: 16px;
        font-family: "Space Grotesk", sans-serif;

        h2 {
            font-size: 32px;
        }

        .linkBtn {
            background: "#242AFC";
            height: 46px;
            border: none;

            &[disabled] {
                background: #0E1D2D !important;
                color: #c7c7c7 !important;
            }
        }
    }

    >* {
        width: 100%;
    }


}

@media (max-width: 767px) {
    main {
        .center {
            width: 100% !important;
        }
    }
}

@media (max-height: 880px) {

    main {


        >div>div.bannerContnt {
            // display: none !important;
            max-height: calc(100vh - 60px);
            overflow: scroll;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}


@media (min-height: 900px) {
    main {
        height: 900px;

        >section {
            max-height: calc(900px - 120px);
            overflow: scroll;
            height: 100%;
        }
    }
}



@media (min-height: 1100px) {
    main {
        height: 900px;

        >section {
            max-height: calc(900px - 120px);
            overflow: scroll;
            height: 100%;
        }
    }
}