.gameSec {
  user-select: none;

  .hexShadow {
    transform: translate(-50%, -50%);
    width: calc(100% + 21px);
  }


 
  .gameBoxWrpper {
    // min-height: calc(100vh - 180px);
    background-color: #0c0c0c;
    max-height: calc(100vh - 186px);
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    padding-top: 18px;
    user-select: none;
  }
  .gameBtn {
    background: "#242AFC";
    max-width: 170px;

    &[disabled] {
      background: #0E1D2D !important;
      color: #c7c7c7 !important;
    }
  }


  .countList {
    z-index: 9;

    .level {
      color: #858586;

      &.active {
        color: #fff;

        svg * {
          fill: #fff;
        }
      }
    }

    // &:after {
    //   position: absolute;
    //   content: "";
    //   height: 100%;
    //   right: 1px;
    //   top: 0;
    //   width: 9px;
    //   z-index: -1;
    //   background-color: #003147;
    //   border-radius: 10px;
    // }
  }

  .gameOptionWrp {
    width: 100%;
    display: grid;
    gap: 2px;
    padding-left: 25px !important;
    user-select: none;

    .listCount {
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      font-size: 12px;
      color: #858586;
      left: -27px;

      &.active {
        color: #fff;
        font-size: 16px;

        svg * {
          fill: #fff;
        }
      }
    }

    li:nth-child(odd) {
      margin-bottom: 4px;
      margin-top: -14px;
      padding-right: 38px;
      background-color: #0c0c0c;
    }

    li:nth-child(even) {
      margin-top: -14px;
      padding-left: 35px;
      margin-bottom: 4px;
      background-color: #0c0c0c;
    }

    li {
      gap: 5px;

      .option {
        width: 20%;
        flex-shrink: 0;
        // opacity: 0.7;
        aspect-ratio: 1;
        z-index: 9;

        svg {
          width: 100%;
        }

        // height: 55px;
        // background: #13131b;
        // border: 2px solid #13131b;
        // border-radius: 10px;
        // clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
        // box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.65);
        .hexIcn {
          top: 50%;
          left: 0;
          width: 100%;
          z-index: -1;
          transform: translateY(-50%);
          user-select: none;

          svg {
            aspect-ratio: 1;
            width: 100%;
            height: 100%;

            * {
              width: 100%;
              height: 100%;
            }
          }
        }

        // &.active {
        //   background-color: #64c2d1;
        //   opacity: 1;
        //   cursor: pointer;
        // }

        &.rightAns {
          // opacity: 1;
          // animation: flipX 2s;

          // background: #0e1d2d;
          // border: 1.88096px solid #242afc;
          // box-shadow: inset 0px 3.76192px 11.2858px rgba(0, 0, 0, 0.25);
        }

        &.wrongAns {
          // opacity: 1;
          // animation: flipX 2s;
          // background: #0e1d2d;
          // border: 1.88096px solid #ed4032;
          // box-shadow: inset 0px 3.76192px 11.2858px rgba(0, 0, 0, 0.25);
        }

        .value {
          font-size: 11px;
          line-height: 11px;
          margin-bottom: -20px;
          font-weight: 700;
          font-family: "Space Grotesk", sans-serif;
        }
      }
    }
  }

  .ctcWrpper {
    background-color: #0c0c0c;
  }

  .ctcBtn {
    gap: 15px;

    >.btn,
    >button,
    >div>button {
      font-family: "Space Grotesk", sans-serif;
      height: 55px;
      font-size: 18px;
      background: hsl(240, 17%, 9%);
      color: #858585;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
      min-width: 100px;
      border: 0;
      background: linear-gradient(0deg, #13131b, #13131b),
        linear-gradient(0deg, #13131b, #13131b),
        linear-gradient(0deg, #13131b, #13131b), #13131b;
      border-radius: 10px;

      &:after {
        display: none;
      }
    }

    p {
      font-size: 10px;
      font-weight: 600;
    }

    .DropdownMenu {
      .item {
        border-top: 1px solid #11afb8;
        // border-bottom: 1px solid #07ECF9;
        text-decoration: none;
        padding: 6px 10px;

        &:hover {
          background-color: #004e8c;
        }
      }
    }
  }

  .header {
    border-radius: 12px !important;

    &::after {
      background: linear-gradient(267.23deg,
          #003b67 -56.5%,
          #00ebfb 49.22%,
          #8dc5fe 149.19%);
    }

    >div {
      background-color: #16546e;
      // height: 45px;
      font-size: 14px;
    }

    >button {
      // height: 45px;
      border-radius: 7px;
      border: 0;

      &.bgBtn {
        background: linear-gradient(183.51deg,
            #8090da71 -101.17%,
            rgba(81, 97, 168, 0) 175.23%);
      }
    }
  }
}

@keyframes flipX {
  0% {
    transform: perspective(600px) rotateY(0deg);
    /* No rotation initially */
  }

  50% {
    transform: perspective(600px) rotateY(180deg);
    /* Half flip */
  }

  100% {
    transform: perspective(600px) rotateY(360deg);
    /* Full flip */
  }
}

//

// css

@media (min-height: 900px) {


  .gameSec {
    .gameBoxWrpper {
      max-height: calc(780px - 100px);
    }

    .ctcWrpper {
      bottom: 5px !important;
      position: relative !important;
    }
  }
}

@media (min-width: 390px) and (max-width: 430px) {
  .gameSec {
    .gameOptionWrp {

      li:nth-child(odd) {
        margin-bottom: 0px;
        margin-top: -14px;
        padding-right: 35px;
      }

      li:nth-child(even) {
        margin-bottom: 0px;
        margin-top: -14px;
        padding-left: 35px;

      }

    }
  }
}

@media (min-width: 376px) and (max-width: 390px) {
  .gameSec {
    .gameOptionWrp {

      li:nth-child(odd) {
        margin-bottom: -4px;
        margin-top: -14px;
        padding-right: 30px;
      }

      li:nth-child(even) {
        margin-bottom: -4px;
        margin-top: -14px;
        padding-left: 30px;

      }

    }
  }
}

@media (max-width: 375px) {
  .gameSec {
    .ctcBtn {
      gap: 10px;

      >.btn,
      >button,
      >div>button {
        min-width: 80px;
      }
    }

    .gameOptionWrp {
      // gap: 3px;


      li:nth-child(odd) {
        margin-bottom: -12px;
        margin-top: -27px;
        padding-right: 30px;
      }

      li:nth-child(even) {
        margin-top: -12px;
        padding-left: 30px;
      }

    }
  }
}

//new