.walletSec {
  .walletForm {
    color: #909095;

    button.submit {
      background: #02BCC0;
      border-color: #02BCC0;
      box-shadow: 0px 2.635px 3.51333px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }

  .mSpinner {
    color: #02a9a8;
  }

  .tab {
    .link {
      color: #fffefd;
      height: 32px;
      min-width: 93px;
      border-radius: 10px !important;
    }
  }

  .cardCstm {
    overflow: hidden;
    z-index: 9;
    opacity: 0.5;
    color: #a5a5a8;
    font-size: 14px;
    line-height: 20px;
    font-family: "Space Grotesk", sans-serif;

    background: #13131b;
    border-radius: 15.3155px;

    .left {
      width: 30%;
    }

    .center {
      width: 30%;
      flex-shrink: 0;
    }

    h6 {
      font-family: "Nunito";
    }

    * {
      z-index: 99;
      font-family: "Space Grotesk";
    }

    // &:after {
    //     position: absolute;
    //     content: "";
    //     left: 0;
    //     top: 0;
    //     height: 100%;
    //     width: 100%;
    //     z-index: -1;
    //     // background: linear-gradient(90deg, #0684AC 0%, #086097 100%);
    //     background-color: #13131B;
    //     // opacity: 0.5;
    //     border-radius: 16px;
    // }

    &.active {
      opacity: 1;
    }

    .wave {
      svg {
        max-width: 100%;
        width: 100%;
      }
    }

    .btn {
      min-width: 74.48px;
      height: 29.34px;
      font-size: 12px;
      font-weight: 700;
      border-color: #02bcc0;
      background: #02bcc0;
      box-shadow: 0px 2.635px 3.51333px rgba(0, 0, 0, 0.25);
      border-radius: 5.72957px;

      &.claimed {
        background: #0593f4;
        border-color: #0593f4;
      }

      &.rejected {
        background: #ED4032;
        border-color: #ED4032;
      }

      &.pending {
        background: #FB8C00;
        border-color: #FB8C00;
      }
    }
  }
}