.siteHeader {
    .header {
        border-radius: 12px !important;
        z-index: 999;
        max-width: 90%;
        margin: 0 auto;

        .innerBox {
            background-color: #0c0c0c;
            // height: 45px;
            font-size: 14px;
            min-width: 125px;
            height: 38px;
        }

        .DropdownMenu {
            right: -20px !important;
            left: unset !important;

            .item {
                text-decoration: none;
                padding: 8px 13px;
                color: #fff;
                font-weight: 500;

                &:hover {
                    background-color: #36aef2;
                }
            }
        }

        >button {
            // height: 45px;
            border-radius: 7px;
            border: 0;

            &.bgBtn {
                background: linear-gradient(183.51deg,
                        #8090da71 -101.17%,
                        rgba(81, 97, 168, 0) 175.23%);
            }
        }
    }
}

@media (max-width :400px) {
    .siteHeader {
        .header {
            max-width: 100%;

            * {
                font-size: 12px;
            }
        }
    }
}