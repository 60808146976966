.leaderBoard {
    .cardCstm {
        // box-shadow: 0px 5.42523px 7.23365px rgba(0, 0, 0, 0.25);e
        background: #101017;
        border-radius: 16px 16px 0px 0px;


        .top {
            h5 {
                font-size: 16px;
            }

            .timer {
                font-size: 12px;
                background: #0A0A0A;
                // opacity: 0.7;
                // border: 1px solid #395895;
                border-radius: 8px;
                min-width: 85px;
            }

            .tab {
                font-size: 12px;
                border-radius: 8px;
                background-color: #0A0A0A;
                cursor: pointer;

                &.active {
                    background-color: #36AEF2;
                }

                &:after {
                    border-radius: 7px;
                }
            }
        }

        table {
            thead {
                th {
                    font-size: 12px;
                    color: #9da1c3;
                }
            }

            tbody {
                tr:nth-child(odd) {

                    // background: linear-gradient(142.68deg, #024677 -138.11%, #0A699D 30.49%, rgba(67, 226, 247, 0.51) 141.28%);
                    // opacity: 0.7;
                    box-shadow: 0px 5.40534px 7.20713px rgba(0, 0, 0, 0.15);
                    border-radius: 7.20713px;
                    background-color: #13131B;
                }

                tr {
                    td {
                        font-size: 12px;
                        color: #9da1c3;

                        * {
                            font-size: 14px;
                            font-weight: 600 !important;
                        }

                        .points {
                            color: #01F4C3;
                        }

                        .dropdown {
                            >button {
                                height: 31px;
                                color: #9da1c3;
                                font-weight: 400;
                                background: linear-gradient(200.7deg,
                                        #5772c4 10.39%,
                                        #578df8 106.19%);
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

                                &:after {
                                    display: none;
                                }
                            }

                            .DropdownMenu {
                                border-color: #3773c7;

                                ol {
                                    li {
                                        border-bottom: 1px solid #3773c7;
                                    }
                                }
                            }
                        }
                    }

                    td:first-child {
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }

                    td:last-child {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }

                tr.active {
                    td {
                        background: #36AEF2 !important;
                        position: sticky;
                        bottom: 0;
                    }
                }
            }
        }
    }
}