@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

body {
  min-height: 100vh;
  /* background-color: #122F47; */
  background-color: #0c0c0c;
  font-family: "nunito";
  font-weight: 5000;
}

/* .modal,
.modal * {
  touch-action: none !important;
} */

/* html,
body {
  overscroll-behavior: none !important;
  overscroll-behavior-y: none !important;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
} */



* {
  scrollbar-width: none;
}

/* * {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
} */

*::-webkit-scrollbar {
  display: none;
}

.fw-sbold {
  font-weight: 600;
}

.themeClr {
  color: #3ccece;
}

td,
th {
  vertical-align: middle;
}

.gap-10 {
  gap: 10px !important;
}

.box {
  z-index: 9;
  position: relative;
  background-color: #13131b;
}

main .container {
  max-width: 430px;
}

.dropdown-toggle:after {
  display: none;
}

.commonBtn {
  background: #242afc;
  box-shadow: 0px 5.99863px 7.99817px rgba(0, 0, 0, 0.25);

  min-width: 115px;
  height: 50px;
  font-size: 16px !important;
  border-radius: 10px !important;
}

.dropdown-menu {
  background: #1d1d2a;
  border-radius: 18px;
  filter: drop-shadow(0px 4px 20px #000000);
}

.modal-content {
  border-radius: 20px !important;
  box-shadow: 0 6px 8px #00000040;
  background: linear-gradient(354deg, rgba(14, 14, 17, 1) 70%, rgb(17 184 250 / 40%) 100%);
  padding: 1px;
}

.modal-content p {
  font-size: 12px;
  line-height: 24px;
}

.modal-content .modalWrpper {
  background: #0e0e11;
  border-radius: 16px;
}

/* pills tab */
.PillsTab.nav-pills .nav-link {
  color: #cfcfcf;
  font-size: 14px;
  border-radius: 12px;
  padding: 10px 8px !important;
  min-width: 81px;
}

.PillsTab.nav-pills .nav-link.active {
  color: #fff;
  background-color: #36aef2;
  box-shadow: 0px 2.26698px 2.26698px rgba(0, 0, 0, 0.15);
}

.accordion .accordion-button:after,
.accordion .accordion-button:before {
  position: absolute;
  content: "";
  width: 22px;
  height: 2.5px;
  border-radius: 5px;
  background: #fff;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.accordion .accordion-button:before {
  height: 22px;
  width: 2.5px;
  right: 35px;
}

.accordion .accordion-button:not(.collapsed):before {
  display: none;
}

.Toastify__toast-container {
  z-index: 99999;
}

.Toastify__toast-container {
  top: 0;
  padding: 0;
  width: 100%;
  max-width: 430px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  transform: unset;
  left: 0;
}

.mineHuntToast {
  background: #242afc;
  border-radius: 0;
  color: #fff;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  line-height: 20px;
  margin-bottom: 0;
  min-height: unset !important;
  padding: 3px !important;
  width: 100% !important;
  z-index: 1000000;
  padding-top: 0 !important;
}

.Toastify__toast-body>div:last-child {
  flex: unset !important;
}

.Toastify__toast-body {
  justify-content: center;
}

.Toastify--animate-icon {
  margin-right: 15px;
}

.mineHuntToast.ErrorToast {
  background: #cb4747;
}

.Toastify__toast-container {
  top: 0;
  padding: 0;
  width: 100%;
  z-index: 10000000;
  max-width: 430px;
}

/* .mineHuntToast .Toastify__toast-body {
  flex: unset;
  margin: 0 auto;
  padding: 0;
} */

.modal-dialog {
  max-width: 430px;
}

.bottomPop.modal .modal-dialog {
  align-items: end;
  display: flex;
  margin: 0 auto;
  height: 100%;
  margin-top: 10px;
}

.bottomPop.modal .modal-dialog .modal-content .modalWrpper {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  /* padding-bottom: 70px !important; */
  overflow: auto;
}

/* body>div#root,
body>div#root main {
  height: 100%;
}

@media (min-width: 1024px) {
  body {
    height: 900px ! IMPORTANT;
    overflow: auto;
    min-height: 900px;
  }
} */


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(100%);
  }
}


/* css */


body.includeHeader main .mainContentWrpper {
  padding-top: 60px !important;
}

div#root,
.siteFooter {
  background-color: #0c0c0c;
}