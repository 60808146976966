.splashScreen {
    .center {

        img {
            // animation: flip 3s infinite linear;
            // transform-style: preserve-3d;
            height: 100vh;
            width: 100%;
        }
    }

    >div {
        gap: 30px;
    }

    .cardCstm {
        background: #191922;
        border-radius: 16px;
        font-family: "Space Grotesk", sans-serif;

        h2 {
            font-size: 32px;
        }

        .linkBtn {
            background: "#242AFC";
            height: 46px;
            border: none;

            &[disabled] {
                background: #0E1D2D !important;
                color: #c7c7c7 !important;
            }
        }
    }
}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

// @media (max-width: 1200px) {
//     .splashScreen {
//         .center {

//             img {
//                 object-fit: cover !important;
//             }
//         }

//     }
// }

@media (max-height: 880px) {

    .splashScreen {


        >div>div.bannerContnt {
            // display: none !important;
            max-height: calc(100vh);
            overflow: scroll;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

@media (max-width: 480px) {
    .splashScreen {
        img {
            object-fit: fill !important;
        }
    }
}